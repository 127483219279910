<template>
  <div>
    <template v-if="value.title && value.url == null">
      <v-chip v-if="value.available" color="primary" outlined small>
        {{ value.size }}</v-chip
      >
      <v-chip v-else small color="danger" outlined>!</v-chip>
    </template>
    <template v-if="!value.title">
      <v-chip v-if="open" small outlined>
        {{ value.documentsCount }}
      </v-chip>
      <v-chip v-else small outlined>
        {{ value.allDocumentsCount }}
      </v-chip>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: ["value", "open"],
});
</script>
